/*
Template Name: Skote - Admin & Dashboard Template
Author: Themesbrand
Version: 4.0.0.
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

@import "theme-light";
@import "theme-dark";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";
@import "custom/components/badge";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/calendar-full";
@import "custom/plugins/dragula";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/icons/fontawesome-all";
@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/timepicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/datatable";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/echarts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/leaflet-maps";

// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/file-manager";
@import "custom/pages/chat";
@import "custom/pages/projects";
@import "custom/pages/contacts";
@import "custom/pages/crypto";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";
@import "custom/pages/jobs";

.fullscreen-modal {
  width: 90% !important;
  margin: 0 auto;
  max-width: 90%;
  height: 100vh;
}
.modal-content {
  position: absolute !important;
  height: 90% !important;
}
.userTable td .rounded-circle.img-fluid {
  width: 130px;
  object-fit: cover;
}
.dashboardCard .card {
  width: -webkit-fill-available;
  margin: 6px;
}
.deleteModal .modal-content {
  height: auto !important;
}
.boxed {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px #ccc;
  margin: 100px 21px 20px;
  padding: 16px 25px;
  position: relative;
}
.boxed img {
  border-radius: 100%;
  height: 80px;
  margin: 1px 0 12px;
  width: 80px;
}
.muiTag span {
  color: #000;
  font-size: 14px;
}
.tagsEvent .innerBoxed a {
  // display: block;
  // width: 240px;
  // min-width: 240px;
  // max-width: 240px;
  // text-overflow: ellipsis;
  // overflow: hidden;
  color: #000 !important;
}
.muiTag .col-md-4 {
  padding-bottom: 14px;
}
.muiTag h4 {
  color: #000;
  font-size: 15px;
  margin-bottom: 0;
}
.heading h3 {
  color: #000;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
}
.fieldDetails label {
  font-size: 16px !important ;
  margin: 5px 0 !important;
}
.fieldDetails input {
  height: 44px;
  border-radius: 8px;
  margin-bottom: 10px;
}
.field-spacing {
  margin-bottom: 10px;
}
.tagsEvent {
  display: flex;
  flex-flow: wrap;
  justify-content: start;
}
.innerBoxed {
  max-width: 20%;
  width: 20%;
  min-width: 20%;
  margin: 0px 0 10px;
  position: relative;
}
.interestBoxed {
  max-width: 20%;
  width: 20%;
  min-width: 20%;
  margin: 0px 0 10px;
  position: relative;
}

.innerBoxed a p {
  display: flex;
  flex-flow: wrap;
  word-break: break-all;
  margin-right: 14px;
}
.innerBoxed p {
  margin-bottom: 1px !important;
}